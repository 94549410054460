import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import { useState } from 'react';

import { useWorkspace } from '@/contexts/useWorkspace';
import { useDAL } from '@/data/dal';
import { PreferencesKey } from '@/data/dal/preferences';
import type { BackupVault } from '@/data/vaults/backupVault';
import type BackupVaultPreferences from '@/data/vaults/backupVaultPrefrences';
import { useTranslation } from '@/i18n/client';

import { SidePanelWrapper } from '../layout/sidePanel/sidePanelWrapper';

const ColorRadioButton = styled(Radio)<{ value: string }>((props) => ({
  display: 'inline',
  width: '24px',
  height: '24px',
  backgroundColor: props.value,
  // borderRadius: '6px',
  margin: '6px',
  '&:first-child': {
    marginLeft: 0,
  },
  '&:hover': {
    backgroundColor: props.value,
    opacity: 0.7,
  },
  '& svg': { display: 'none' },
  '&.Mui-checked': {
    border: '2px solid gray',
  },
  '&.Mui-disabled': {
    opacity: 0.5,
  },
}));

const LargeCheckBox = styled(Checkbox)({
  margin: '-3px',
  marginRight: '5px',
  padding: 0,
  '& svg': {
    fontSize: '28px',
  },
  '&.Mui-disabled svg': {
    opacity: 0.6,
  },
});

export interface EditBackupVaultPanelProps {
  entity: BackupVault;
  onChange: () => void;
}

enum EditMode {
  Create,
  Edit,
}

export const VaultColors = [
  '#AADAFF',
  '#88F0B0',
  '#D7C2FF',
  '#FBCB8E',
  '#FFDD61',
  '#F9C1FB',
  '#FBB2B0',
];

export function EditBackupVaultPanel(props: EditBackupVaultPanelProps) {
  const { t } = useTranslation('vaults');
  const [data, setData] = useState(props.entity);
  const [error, setError] = useState<string | undefined>();
  const { rightPanel } = useWorkspace();
  const { setIsOpen } = rightPanel;
  const close = () => setIsOpen(false);
  const dal = useDAL();
  const [loading, setLoading] = useState(false);
  const mode = props.entity.id ? EditMode.Edit : EditMode.Create;
  const { body: pref, isLoading: prefLoading } =
    dal.preferences.getCustomerPref(PreferencesKey.BackupVault);
  const { body: accounts, isLoading: loadingAccounts } =
    dal.vaults.getAccounts();
  const account = data.cloudAccountId
    ? accounts?.accounts?.find((a) => a.id === data.cloudAccountId)
    : undefined;

  function validate(): boolean {
    if (!data.name || data.name.length < 10) {
      setError('Name must be at least 10 characters long');
      return false;
    }

    const regexp = /^[a-zA-Z0-9-_ ]+$/;
    if (data.name.search(regexp) === -1) {
      setError('Name can only contain letters, numbers, and hyphens');
      return false;
    }

    if (mode === EditMode.Create) {
      if (!data.cloudProvider) {
        setError('Please select a cloud provider');
        return false;
      }

      if (!data.region) {
        setError('Please select a region');
        return false;
      }
    }

    return true;
  }

  function clearValidationError() {
    setError(undefined);
  }

  async function save() {
    if (!validate()) return;

    setLoading(true);
    if (data.id) {
      await dal.vaults.update(data);
    } else {
      const res = await dal.vaults.create(data);
      data.id = res?.id || '';
    }

    if (data.backgroundColor && !prefLoading) {
      if (!pref?.value) {
        void dal.preferences.updateCustomerPref(PreferencesKey.BackupVault, {
          colors: [{ id: data.id, color: data.backgroundColor }],
        } satisfies BackupVaultPreferences);
      } else {
        const vpref = (pref.value as BackupVaultPreferences)?.colors.find(
          (p) => p.id === data.id
        );
        if (vpref) {
          vpref.color = data.backgroundColor;
        } else {
          (pref.value as BackupVaultPreferences)?.colors.push({
            id: data.id,
            color: data.backgroundColor,
          });
        }

        void dal.preferences.updateCustomerPref(
          PreferencesKey.BackupVault,
          pref.value
        );
      }
    }

    setIsOpen(false);
    props.onChange?.();
  }

  return (
    <>
      <SidePanelWrapper
        close={close}
        title={props.entity.id ? t('titles.edit') : t('titles.create')}
      >
        <Box className='h-full flex flex-col p-[20px]'>
          <Typography variant='body1'>
            Backup vaults sets the target for your backups. Please specify the
            following details to create a new backup vault.
          </Typography>
          <FormControl size='small' className='w-full mt-[24px]'>
            <FormLabel>{t('fields.name')}</FormLabel>
            <TextField
              disabled={loading}
              size='small'
              defaultValue={data.name}
              inputProps={{ 'data-testid': 'vault-name' }}
              onChange={(event) => {
                clearValidationError();
                setData({
                  ...data,
                  name: event.target.value,
                });
              }}
            />
          </FormControl>
          <FormControl size='small' className='w-full mt-[24px]'>
            <FormLabel>{t('fields.cloudProvider')}</FormLabel>
            <Select
              disabled={loading || mode === EditMode.Edit}
              value={data.cloudProvider}
              data-testid='vault-cloud-provider'
              onChange={(event) => {
                clearValidationError();
                setData({ ...data, cloudProvider: event.target.value });
              }}
            >
              <MenuItem value='AWS' data-testid='vault-cloud-provider-AWS'>
                AWS
              </MenuItem>
              <MenuItem
                disabled
                value='gcp'
                data-testid='vault-cloud-provider-GCP'
              >
                GCP
              </MenuItem>
              <MenuItem value='azure' data-testid='vault-cloud-provider-Azure'>
                Azure
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl size='small' className='w-full mt-[24px]'>
            <FormLabel>{t('fields.account')}</FormLabel>
            <Select
              disabled={loadingAccounts || mode === EditMode.Edit}
              value={data.cloudAccountId}
              data-testid='vault-cloud-account'
              onChange={(event) => {
                clearValidationError();
                setData({ ...data, cloudAccountId: event.target.value });
              }}
            >
              {accounts?.accounts?.map((account, i) => (
                <MenuItem
                  value={account.id}
                  key={i}
                  data-testid={`vault-cloud-account-${i}`}
                >
                  {account.name} ({account.id})
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl size='small' className='w-full mt-[24px]'>
            <FormLabel>{t('fields.region')}</FormLabel>
            <Select
              disabled={
                loadingAccounts ||
                mode === EditMode.Edit ||
                data?.cloudAccountId === ''
              }
              data-testid='vault-region'
              value={data.region}
              onChange={(event) => {
                clearValidationError();
                setData({ ...data, region: event.target.value });
              }}
            >
              {account?.regions?.map((region, i) => (
                <MenuItem
                  value={region}
                  key={i}
                  data-testid={`vault-region-${region}`}
                >
                  {region}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl size='small' className='w-full mt-[24px]'>
            <FormLabel>{t('fields.bucket')}</FormLabel>
            <TextField
              disabled={loading || mode === EditMode.Edit}
              size='small'
              defaultValue={data.bucketName}
              inputProps={{ 'data-testid': 'vault-bucket' }}
              onChange={(event) => {
                clearValidationError();
                setData({
                  ...data,
                  bucketName: event.target.value,
                });
              }}
            />
          </FormControl>
          <FormControl size='small' className='w-full mt-[24px]'>
            <FormLabel>{t('fields.bucketPrefix')}</FormLabel>
            <TextField
              disabled={loading || mode === EditMode.Edit}
              size='small'
              defaultValue={data.bucketPrefix}
              inputProps={{ 'data-testid': 'vault-bucket-prefix' }}
              onChange={(event) => {
                clearValidationError();
                setData({
                  ...data,
                  bucketPrefix: event.target.value,
                });
              }}
            />
          </FormControl>
          <FormControl
            size='small'
            className='w-full mt-[24px] flex flex-row items-center'
          >
            <LargeCheckBox
              disabled={loading || mode === EditMode.Edit}
              checked={data.objectLock}
              inputProps={{ ['data-testid' as string]: 'vault-object-lock' }}
              onChange={(event) => {
                clearValidationError();
                setData({ ...data, objectLock: event.target.checked });
              }}
            />
            <FormLabel className='mb-0'>{t('fields.objectLock')}</FormLabel>
          </FormControl>
          <FormControl
            size='small'
            className='w-full mt-[24px] flex flex-row items-center justify-between'
          >
            <FormLabel className='mb-0'>
              {t('fields.backgroundColor')}
            </FormLabel>
            <RadioGroup
              className='flex-row'
              aria-labelledby='demo-controlled-radio-buttons-group'
              name='controlled-radio-buttons-group'
              value={data.backgroundColor || VaultColors[0]}
              onChange={(event) => {
                setData({ ...data, backgroundColor: event.target.value });
              }}
            >
              {VaultColors.map((color) => (
                <ColorRadioButton
                  data-testid={`vault-color-btn`}
                  key={color}
                  value={color}
                  inputProps={{
                    ['data-testid' as string]: `vault-color-${color}`,
                  }}
                  disabled={loading}
                />
              ))}
            </RadioGroup>
          </FormControl>
          {error && (
            <div style={{ height: '25px', marginTop: '35px' }}>
              <Typography color='error' variant='body2'>
                <i className='clarity-error-line text-[22px] text-error align-middle mb-[2px]' />
                {error}
              </Typography>
            </div>
          )}
        </Box>
        <Box className='flex justify-end px-[20px] py-[16px]'>
          <Button
            disabled={loading}
            size='small'
            variant='outlined'
            className='mr-[12px]'
            onClick={() => setIsOpen(false)}
          >
            {t('buttons.cancel')}
          </Button>
          <Button
            disabled={loading}
            size='small'
            variant='contained'
            data-testid='save-vault'
            onClick={() => {
              void save();
            }}
          >
            {props.entity.id ? t('buttons.save') : t('buttons.create')}
          </Button>
        </Box>
      </SidePanelWrapper>
    </>
  );
}
