import { Divider, Stack, Typography } from '@mui/material';
import type { PropsWithChildren } from 'react';

import { Icon } from '@/components/shared/icon';

export interface SidePanelWrapperProps extends PropsWithChildren {
  close: () => void;
  icon?: React.ReactNode;
  hideBorder?: boolean;
  title: string;
  subtitle?: string;
}

export const SidePanelWrapper = (props: SidePanelWrapperProps) => {
  return (
    <Stack className='h-full'>
      <Stack
        justifyContent='space-between'
        direction='row'
        alignItems='center'
        padding='16px 24px'
      >
        <Stack alignItems='center' direction='row'>
          {props.icon && <div className='mr-[15px]'>{props.icon}</div>}
          <div className='flex flex-col'>
            <Typography className='font-semibold'>{props.title}</Typography>
          </div>
        </Stack>
        <div className='flex gap-4'>
          <Icon
            onClick={props.close}
            iconClass='material-symbols-close-rounded'
          />
        </div>
      </Stack>
      {props.subtitle && (
        <Typography
          className='px-[24px] pb-[20px] pt-[8px]'
          variant='subtitle1'
          fontSize={14}
        >
          {props.subtitle}
        </Typography>
      )}
      {!props.hideBorder && <Divider />}
      <div className='flex flex-col overflow-auto rounded-b-[16px] h-full'>
        {props.children}
      </div>
    </Stack>
  );
};
