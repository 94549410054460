// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const parseJson = <T = any>(
  value: string | undefined
): T | undefined => {
  try {
    return value ? JSON.parse(value) : undefined;
  } catch (error) {
    return undefined;
  }
};

export function stringToHash(input: string, max = 10): number {
  let hash = 0;

  if (input.length === 0) return hash;

  for (let i = 0; i < input.length; i++) {
    const c = input.charCodeAt(i);
    hash = (hash << 5) - hash + c;
    hash = hash & hash;
  }

  return Math.abs(hash) % max;
}
