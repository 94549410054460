export const Panels = {
  InventoryInstance: 'InventoryInstance',
  FileSearchInstance: 'FileSearchInstance',
  RestoreVolumeWizard: 'RestoreVolumeWizard',
  RestoreFileWizard: 'RestoreFileWizard',
  EditBackupVault: 'EditBackupVault',
  BackupPolicy: 'BackupPolicy',
  User: 'User',
  Group: 'Group',
  SAML: 'SAML',
  Project: 'Project',
  Filters: 'Filters',
  FiltersDrillDown: 'FiltersDrillDown',
  Control: 'Control',
  CloudAccount: 'CloudAccount',
} as const;

export type Panels = (typeof Panels)[keyof typeof Panels];
